const INFO = {
	main: {
		title: "Nfon Andrew",
		name: "Nfon Andrew T.",
		email: "dev.n.andrew@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/nfonandrew73",
		github: "https://github.com/andrew21-mch",
		linkedin: "https://www.linkedin.com/in/nfon-andrew-7703a11a0/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Full-stack developer with Experience in PHP/Laravel, MySQL, Python, Js, Some React",
		description: "I am a full-stack developer with expertise in PHP/Laravel, MySQL, Python, JavaScript, and some React. I specialize in Laravel for backend development. My experience includes building scalable, secure, and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. Always seeking new challenges and opportunities to grow as a developer."
},

about: {
    title: "I’m Nfon Andrew, I currently reside in Cameroon and Very Flexible remote developer",
    description: `
        I got into computers when I was 16, even though I didn't have much access back then. 
        I've been writing code for about four years now. I'm pretty good with PHP, and I can work with lots of other programming languages too, like Python and Java. 
        I'm a good team player and leader. I like to take charge and get things done. 
        Right now, I'm the Chief Technology Officer at a startup called Skye8. In this role, I help come up with new ideas and make sure things run smoothly.<br><br> 
        Outside of work, I teach people how to code on websites like Preply. 
        I love technology and how it can change the world. My goal is to keep learning and growing, and maybe even help others do the same along the way.<br> 
        I have worked with several companies as a software engineer, backend developer, and full-stack developer. I was also a mentor at Zuri.<br> 
        In my free time, I enjoy watching movies and spending time in nature.<br> <br>
        My journey in the world of technology has been rewarding and fulfilling. 
        I'm proud to have turned my passion into a profession. 
        As I continue to learn and grow, I recently started getting into AI, building ML models, and integrating them into projects. 
        I hope to inspire others to embrace the power of technology and never stop exploring new possibilities.
    `,
},




	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "SmartShop Management System",
			description:
				"SmartShop is a versatile application for store owners to manage inventory and enhance customer service",
			logo: "https://cdn.worldvectorlogo.com/logos/laravel-2.svg",
			linkText: "View Project",
			link: "https://github.com/andrew21-mch/inventory_localization_api",
		},

		{
			title: "Resume Generator API",
			description:
				"The Resume Generator API is a tool that enables users to automatically generate professional resumes.				",
			logo: "https://cdn.worldvectorlogo.com/logos/laravel-2.svg",
			linkText: "View Project",
			link: "https://github.com/andrew21-mch/resumeBuilder_API",
		},

		{
			title: "ANTIC Crime Reporting and Fraud Detection System",
			description:
				"ANTIC is a platform that enables individuals to report crimes and be able to verify the authenticity of messages they receive",
			logo: "https://cdn.worldvectorlogo.com/logos/laravel-2.svg",
			linkText: "View Project",
			link: "https://crs.flextrain.ing",
		},

		{
			title: "iSend Mobile Application",
			description:
				"iSend is a mobile application that facilitates pickups and deliveries of items at home through user requests.",
			logo: "https://cdn.worldvectorlogo.com/logos/flutter.svg",
			linkText: "View Project",
			link: "https://play.google.com/store/apps/details?id=com.citysolution.isend&pcampaignid=web_share",
		},

		{
			title: "Femssa API",
			description:
				"API for a platform to enable students get updates from student government, also integrats payments",
			logo: "https://cdn.worldvectorlogo.com/logos/laravel-2.svg",
			linkText: "View Project",
			link: "https://www.femssa.com",
		},

		{
			title: "Internship Management and Training Portal",
			description:
				"A portal that helps organizations organize and offer trainings and internships",
			logo: "https://cdn.worldvectorlogo.com/logos/laravel-1.svg",
			linkText: "View Project",
			link: "https://github.com",
		},
	],

	stacks : [
		{
			title: "PHP/Laravel",
			description: "Experience with PHP framework Laravel",
			logo: "https://cdn.worldvectorlogo.com/logos/laravel-2.svg",
			level: 'expert'
		},
		{
			title: "Flutter",
			description: "Proficient in building mobile apps with Flutter",
			logo: "https://cdn.worldvectorlogo.com/logos/flutter.svg",
			level: 'beginner'
		},
		{
			title: "Java",
			description: "Some experience with Java",
			logo: "https://cdn.worldvectorlogo.com/logos/java-4.svg",
			level: 'intermediate'
		},
		{
			title: "NestJS",
			description: "Experience with NestJS framework for Node.js",
			logo: "https://cdn.worldvectorlogo.com/logos/nestjs.svg",
			level: 'intermediate'
		},
		{
			title: "Python",
			description: "Worked with Python programming language",
			logo: "https://cdn.worldvectorlogo.com/logos/python-4.svg",
			level: 'advanced'
		},
		{
			title: "React",
			description: "Experience with React library for building UIs",
			logo: "https://cdn.worldvectorlogo.com/logos/react-2.svg",
			level: 'beginner'
		},
		{
			title: "Angular",
			description: "Some experience with Angular framework",
			logo: "https://cdn.worldvectorlogo.com/logos/angular-3.svg",
			level: 'intermediate'
		},
		{
			title: "Software Design",
			description: "Experience in architecture and software design",
			logo: "https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg",
			level: 'beginner'
		}
		
	]

};

export default INFO;
